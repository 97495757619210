
.editor-tools {
    z-index: 30;
    position: absolute;
}

.editor-tools.editor-tools-top-left {
    top: 0;
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.editor-tools.editor-tools-top-right {
    top: 0;
    right: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.editor-tools.editor-tools-bottom-left {
    bottom: 0;
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.editor-tools.editor-tools-bottom-right {
    bottom: 0;
    right: 0;
}

.editor-tools button:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.editor-tools button:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #eee;
}

.editor-tools button:focus {
    z-index: 2;
}

.editor-tools button {
    font-size: 2em;
    border-radius: 0;
    border: 1px solid #eee;
    border-bottom: none;
    appearance: none;
    background-color: white;
    min-width: 40px;
    min-height: 40px;
    z-index: 1;
    margin: 0;
}

.editor-tool-set {
  display: flex;
  flex-direction: column;
}

.editor-tools button[aria-selected=true] {
  color: white;
  background: dodgerblue;
}

.editor-tools-top-left button {
}

.editor-tools-top-right button {
}
